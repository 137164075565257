<style lang="scss" scoped>
/deep/ .comp-member-capdetail {
    margin-top: 6vh !important;
    max-width: 650px;
    .el-tag {
        width: 100%;
        height: auto;
        min-height: 28px;
        white-space: normal;
        background-color: #fdfdfd;
    }
}
</style>

<template>
    <div>
        <el-dialog title="余额日志详细" custom-class="c-el-dialog comp-member-capdetail" 
            :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="会员ID/名称">
                            <el-tag type="info">{{ dialogData.memberId }}/{{ dialogData.member && dialogData.member.name || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="类型">
                            <el-tag type="info">{{ dialogData.changeReasonDesc || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="时间">
                            <el-tag type="info">{{ dialogData.creationTime || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="变更金额">
                            <el-tag type="info">{{ dialogData.amount || noData }}元</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" v-if="dialogData.extensionInfo">
                        <el-form-item label="参于分成的订单金额">
                            <el-tag type="info">{{ dialogData.extensionInfo.orderAmount || noData }}元</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" v-if="dialogData.extensionInfo">
                        <el-form-item label="收益分成比例">
                            <el-tag type="info">{{ dialogData.extensionInfo.profitRate || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="变更后的当前余额">
                            <el-tag type="info">{{ dialogData.currentBalance || noData }}元</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="设备号">
                            <el-tag type="info">{{ dialogData.deviceId || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="设备型号">
                            <el-tag type="info">{{ dialogData.deviceTypeId || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="设备所属商家">
                            <el-tag type="info">{{ dialogData.merchant && dialogData.merchant.name || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="设备所属代理">
                            <el-tag type="info">{{ dialogData.agent && dialogData.agent.name || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-tag type="info">{{ dialogData.remark || noData }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "compMemberDetail",
    data() {
        return {
            showDialog: false,
            dialogData: {},
            noData: "--"
        }
    },
    methods: {
        show(row) {
            this.dialogData = row
            this.showDialog = true
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>