<style lang="scss" scoped>
.page-member-capitallogs {
    .user-avatar {
        width: 50px;
        height: 50px;
        background-size: cover;
        border-radius: 50%;
        display: block;
        margin-right: 10px;
    }
    .user-info {
        display: flex;
        align-items: center;
        //   justify-content: center;
        .user-name {
            flex: 1;
            overflow: hidden;
        }
    }
}
</style>

<template>
    <div class="page-member-capitallogs">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl"></div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        @change="_search" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" size="medium" align="right">
                    </el-date-picker>
                    <el-select class="mrgr5 mrgb5" v-model="filter.reasons" placeholder="日志类型" multiple 
                        @change="_search()" clearable size="medium">
                        <el-option :value="item.value" :label="item.name" v-for="item in reasonOptions" :key="item.value"></el-option>
                    </el-select>
                    <el-select class="mrgr5 mrgb5" v-model="filter.merchantId" filterable remote reserve-keyword placeholder="商家(输入搜索)" clearable
                        @change="_search()" :remote-method="renderMerchantOptions" size="medium">
                        <el-option v-for="item in merchantItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <el-select class="mrgr5 mrgb5" v-model="filter.memberId" filterable remote reserve-keyword placeholder="会员(输入搜索)" clearable
                        @change="_search()" :remote-method="renderMemberOptions" size="medium">
                        <el-option v-for="item in memberItems" :key="item.id" :label="`${item.name}【${item.id}】`" :value="item.id"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="设备号/订单号" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-alert class="mrgb10 colff0" type="error">
                总金额:{{ tableItems.totalAmount }}
            </el-alert>
            <el-table :data="tableData" border fit highlight-current-row row-key="id" @sort-change="sortChange">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="orderId" label="订单号"></el-table-column>
                <el-table-column label="会员" >
                    <template slot-scope="scope">
                        <div class="user-info" @click="_query(scope.row.member)">
                            <div class="user-avatar" :style="`background-image:url(${scope.row.member.head})`"></div>
                            <div class="store-name">
                                <div>{{scope.row.member.name}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="changeReasonDesc" label="类型" sortable="custom"></el-table-column>
                <el-table-column label="金额" sortable="custom">
                    <template slot-scope="scope">{{ scope.row.amount > 0 ? '+' : '' }}{{ scope.row.amount }}</template>
                </el-table-column>
                <el-table-column prop="currentBalance" label="变更后余额" sortable="custom"></el-table-column>
                <el-table-column prop="creationTime" label="时间" sortable="custom" width="160"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="showDetails(scope.row)">查看</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--余额日志详情-->
        <detail :ref="refDetail"></detail>
        <!--会员详情-->
        <member-detail :ref="refMemberDetail"></member-detail>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funMember from "@/api/member/index"
import * as funMerchant from "@/api/merchant/index"
import detail from "./components/capDetail"
import MemberDetail from "./components/detail"
export default {
    name: "pageMemberCapitallogs",
    mixins: [enumConfigs],
    components: { detail, MemberDetail },
    data() {
        const that = this
        return {
            refDetail: "refCapitallogsToCapDetail",
            refMemberDetail: "refCapitallogsToMemberDetail",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                memberId: null,
                reasons: null,
                startDate: null,
                endDate: null,
                merchantId: "",
                keywords: "",
                page: 1,
                size: window.$common.appPageSize,
                sortField: null,
                sortDirection: 0
            },
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            merchantItems: [],
            memberItems: []
        }
    },
    computed: {
        reasonOptions() {
            return this.arrMemberBalanceChangeReason
        }
    },
    activated() {
        if (this.$route.query.id) {
            this.filter.memberId = Number(this.$route.query.id)
            this.renderMemberOptions(this.filter.memberId)
        }
        this.getDataList()
        
    },
    created() {
        if (this.$route.query.id) {
            this.filter.memberId = Number(this.$route.query.id)
            this.renderMemberOptions(this.filter.memberId)
        }
        this.renderMerchantOptions("")
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.filter.startDate = timeArr[0] || ""
            this.filter.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.filter.startDate = psTime
            this.filter.endDate = peTime
            this.selectedTime = [this.filter.startDate, this.filter.endDate]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funMember.QueryBalanceChangeLogs(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        },
        async renderMemberOptions(query) {
            await funMember.QueryMemberList({ 
                page: 1,
                size: 10,
                keywords: query
            }).then(res => {
                this.memberItems = res.items
            })
        },
        showDetails(row) {
            this.$refs[this.refDetail].show(row)
        },
        _query(row) {
            this.$refs[this.refMemberDetail].show(row)
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        },
        async sortChange(e) {
            if (e.order) {
                this.filter.sortField = e.prop
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this.getDataList()
        }
    }
}
</script>