<style lang="scss" scoped>
/deep/ .comp-member-detail {
    margin-top: 6vh !important;
    max-width: 450px;
    .el-tag {
        width: 100%;
        height: auto;
        min-height: 28px;
        white-space: normal;
        background-color: #fdfdfd;
    }
}
</style>

<template>
    <div>
        <el-dialog title="会员详情" custom-class="c-el-dialog comp-member-detail" 
            :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="头像">
                            <a :href="dialogData.head" target="_blank">
                                <img :src="dialogData.head" width="50" height="50" />
                            </a>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="用户昵称/ID">
                            <el-tag type="info">{{ dialogData.name }}【{{ dialogData.id }}】</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="手机号">
                            <el-tag type="info">{{ dialogData.phoneNumber || "--" }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="来源">
                            <el-tag type="info">{{ dialogData.clientTypeDesc }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="余额">
                            <el-tag type="info">{{ dialogData.balance }}元</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="今日收入">
                            <el-tag type="info">{{ dialogData.todayProfit }}元</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="注册时间">
                            <el-tag type="info">{{ dialogData.creationTime }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funMember from "@/api/member/index"
export default {
    name: "compMemberDetail",
    data() {
        return {
            showDialog: false,
            dialogData: {}
        }
    },
    methods: {
        show(row) {
            window.$common.fullLoading()
            funMember.GetMemberInfo({ 
                id: row.id,
                includeAgent: true,
                includeMerchants: true,
                StatisticTodayProfit: true
            }).then(res => {
                window.$common.closeFullLoading()
                this.dialogData = res
                this.showDialog = true
            })
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>